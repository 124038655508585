$IS_SE_SITE: false; $IS_NO_SITE: true;
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';

.btn {
  display: flex;
  position: absolute;
  top: 10px;
  right: 15px;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  transition: transform 0.15s ease;
  border-radius: 50%;
  border-color: #fff;

  @include hover {
    transform: scale(1.2);
  }

  :global .icon-close {
    width: 18px;
    height: 18px;
    fill: #fff;
  }
}

@include breakpoint($breakMedium) {
  .btn {
    top: 18px;
    right: 20px;
  }

  .large {
    width: 32px;
    height: 32px;

    :global .icon-close {
      width: 32px;
      height: 32px;
    }
  }
}

.large {
  width: 24px;
  height: 24px;

  :global .icon-close {
    width: 24px;
    height: 24px;
  }
}

.invert {
  border-color: #000;

  :global .icon-close {
    fill: #20335d;
  }
}

.muted {
  opacity: 0.5;
}

.border {
  border-width: 1px;
  border-style: solid;
}
