$IS_SE_SITE: false; $IS_NO_SITE: true;
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';

.header {
  margin: 20px 0;
  @include breakpoint($breakMedium) {
    margin: 40px 0 18px;
  }

  h1 {
    @extend %fontSemiBold;

    margin: 8px 0;
    font-size: 26px;
    line-height: 1.25;

    @include breakpoint($breakMedium) {
      font-size: 36px;
    }
  }
}

.form {
  margin-top: 32px;
}

.buttons {
  margin-top: 4px;
}

.button {
  @extend %fontBold;

  min-width: 100px;
  margin-top: 10px;
  padding: 0 14px;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  border-radius: 2px;
  background-color: #d8d8d8;
  font-size: 14px;
  line-height: 38px;

  &:hover {
    background-color: darken(#d8d8d8, 5%);
  }

  &.submit {
    margin-right: 10px;
    background-color: #1b89c6;
    color: #fff;

    &:hover {
      background-color: darken(#1b89c6, 5%);
    }
  }
}

.loadingContainer {
  margin-top: 30vh;
}

.betInfoTable {
  margin: 32px 0;
  padding: 10px 14px;
  border: 1px solid #bbb;
  background: #fafafa;

  td {
    word-break: break-word;
  }

  td:first-child {
    padding-right: 10px;
    white-space: nowrap;
    @extend %fontSemiBold;
  }
}

.explanation {
  line-height: 1.5;
}

.radio {
  margin: 6px 0;
}

.input input {
  width: 100%;
  max-width: 400px;
  height: 40px;
  margin: 6px 0;
  border: 1px solid #888;

  &:disabled {
    border-color: #ccc;
    background: #f8f8f8;
    color: #666;
  }
}
