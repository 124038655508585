$IS_SE_SITE: false; $IS_NO_SITE: true;
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';

$btnSubmitBg: #0093ff;
$btnSubmitBorder: #0076c8;
$btnSubmitColor: #fff;
$btnCancelBg: #ddd;
$btnCancelBorder: #ccc;
$btnCancelColor: #666;

.root {
  background: #fff;
}

.header {
  padding: 12px;
  background: #4ba4d6;

  h1 {
    margin: 0;
    @extend %fontSemiBold;

    font-size: 16px;
  }

  @include breakpoint($breakMedium) {
    padding: 18px 16px;

    h1 {
      font-size: 18px;
    }
  }
}

.body {
  position: relative;
  min-height: 110px;
  padding: 0 12px 12px;
  color: #000;
  @include breakpoint($breakMedium) {
    padding: 0 16px 16px;
  }
}

.section {
  margin-top: 12px;
  padding: 12px;
  background: #eee;

  @include breakpoint($breakMedium) {
    margin-top: 20px;
    padding: 15px;
  }
}

.sectionTitle {
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
  @extend %fontSemiBold;
}

.inputTitle {
  margin-top: 16px;
}

.textArea {
  margin-top: 15px;
  color: #000;
}

.commentContainer {
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
}

.comment {
  margin: 15px 0 6px;
  font-size: 12px;
  line-height: 1.4;
  word-break: break-word;
  white-space: pre-line;
}

.commentDate {
  color: #888;
  font-size: 11px;
}

.commentButtonContainer {
  margin-top: 15px;
}

.commentButton {
  display: inline-flex;
  align-items: center;
  height: 24px;
  margin-right: 16px;
  color: #005ca0;
  font-size: 12px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    fill: #005ca0;

    &.penIcon {
      width: 14px;
      height: 14px;
    }
  }

  &:hover {
    opacity: 0.9;
  }
}

.deleteCommentButton svg {
  transform: rotate(45deg);
}

.commentEditButtonContainer {
  margin: 12px 0 3px;
}

.button {
  @extend %fontBold;

  min-width: 100px;
  height: 38px;
  margin-right: 12px;
  padding: 0 16px;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 12px;
  line-height: 38px;

  &.submit {
    margin-right: 10px;
    border-color: $btnSubmitBorder;
    background-color: $btnSubmitBg;
    color: $btnSubmitColor;

    &:hover {
      border-color: darken($btnSubmitBorder, 5%);
      background-color: darken($btnSubmitBg, 5%);
    }
  }

  &.cancel {
    border-color: $btnCancelBorder;
    background-color: $btnCancelBg;
    color: $btnCancelColor;

    &:hover {
      border-color: darken($btnCancelBorder, 5%);
      background-color: darken($btnCancelBg, 5%);
    }
  }
}

.commitButtons {
  margin-top: 16px;
}

.checkboxContainer {
  padding: 16px 0;
  border-bottom: 1px solid #ccc;
}
